import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserFriends, faLaptop, faPhone, faCoins } from "@fortawesome/free-solid-svg-icons"
import Layout from "../components/layout/layout"
import Banner from "../components/Banner/Banner"
import Button from "../components/Button/Button"
import CTA from "../components/CTA/CTA"
import Underline from "../components/Underline/Underline"
import YourTherapyStyles from "./therapy-your-way.module.css"
import MetaImage from "../images/autumn-road.jpg"

const Service = props => (
    <div className={YourTherapyStyles.service}>
        <span className={YourTherapyStyles.icon}>
            <FontAwesomeIcon icon={props.icon} style={{fontSize: 24}} />
        </span>
        {props.children}
    </div>
)

export default function YourTherapy({ data }) {
    const content = data.allContentYaml.edges[0].node

    return (
        <Layout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Therapy Your Way | Paula Abbey Counselling & Psychotherapy</title>
                <meta name="title" content="Therapy Your Way | Paula Abbey Counselling & Psychotherapy" />
                <meta name="description" content="It's important that you're comfortable with your therapist, but also that you choose the communication method that fits you best. I offer Face to Face, Online & Telephone Counselling, letting you choose what works for you." />

                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://paulaabbey.co.uk/therapy-your-way/" />
                <meta property="og:title" content="Therapy Your Way | Paula Abbey Counselling & Psychotherapy" />
                <meta property="og:description" content="It's important that you're comfortable with your therapist, but also that you choose the communication method that fits you best. I offer Face to Face, Online & Telephone Counselling, letting you choose what works for you." />
                <meta property="og:image" content={MetaImage} />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:url" content="https://paulaabbey.co.uk/therapy-your-way/" />
                <meta name="twitter:title" content="Therapy Your Way | Paula Abbey Counselling & Psychotherapy" />
                <meta name="twitter:description" content="It's important that you're comfortable with your therapist, but also that you choose the communication method that fits you best. I offer Face to Face, Online & Telephone Counselling, letting you choose what works for you." />
                <meta name="twitter:image" content={MetaImage} />
            </Helmet>
            <Banner image={data.background.childImageSharp.fluid}>
                <h1>Find the best fit for you.</h1>
            </Banner>
            <section>
                <div className={YourTherapyStyles.text}>
                    <Underline>
                        <h2>Your therapy</h2>
                    </Underline>
                    <p>I fully understand the nerves which surround finding a counsellor, therefore I aim to make the process as comfortable as possible. The most important thing is that you find a therapist that you like and feel comfortable with. An initial 20 minute telephone session gives us the opportunity to discuss your needs and expectations of therapy and work out if we are a good fit. If I am not the therapist for you, I have a network of excellent colleagues and I will happily help you find the right person.</p>
                    <p>Once therapy is agreed, it is also important to consider which method of communication fits you best.</p>
                </div>
                <div className={YourTherapyStyles.servicesContainer}>
                    <Service icon={faUserFriends}>
                        <h3>Face to Face Counselling</h3>
                        <p><strong>I have now returned to face-to-face sessions with COVID-19 safety procedures.</strong></p>
                        <p>I'd be glad to welcome you to my private and confidential therapy room in Kelsall, Tarporley, which is easily accessible from Chester.</p>
                    </Service>
                    <Service icon={faLaptop}>
                        <h3>Online Counselling</h3>
                        <p>Maybe you would prefer to engage in therapy from the comfort and convenience of your own home, especially helpful if you have a health condition, disability or are restricted to travel. It also allows for greater choice of therapist as you are not restricted by location. I use Zoom as it is an extremely secure video platform which is easy to use.  All you need is a device you are familiar with, a good internet connection and a private space to talk.</p>
                    </Service>
                    <Service icon={faPhone}>
                        <h3>Telephone Counselling</h3>
                        <p>If you like the idea of working remotely, but online does not feel right for you, you could opt for telephone counselling. It can offer you a greater sense of confidentiality and anonymity, which can be reassuring particularly if you feel nervous, embarrassed, or fearful about talking through your problems.</p>
                    </Service>
                </div>
                <div className={YourTherapyStyles.fees}>
                    <Service icon={faCoins}>
                        <h3>Fees</h3>
                        <p>Whichever method of communication you choose, appointments are 50 minutes long with fees starting at £{content.fees}. I currently offer weekday daytime appointments in term time only. If you decide you want to work with me, we will find a regular time each week or fortnight to meet.</p>
                    </Service>
                </div>
            </section>
            <div className={YourTherapyStyles.buttonContainer}>
                <h3>Ready to make a start?</h3>
                <Button type="internal" colour="primary" to="/appointments/">Book an appointment</Button>
            </div>
            <section className={YourTherapyStyles.cta}>
                <CTA>
                    <h2>Questions before getting started?</h2>
                    <Button type="internal" colour="secondary" to="/faq/">Visit Frequently Asked Questions</Button>
                </CTA>
            </section>
        </Layout>
    )
}

export const query = graphql`
    query {
        background: file(relativePath: {eq: "images/autumn-road.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 2000, quality: 72) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        allContentYaml {
            edges {
                node {
                    fees
                    discount
                }
            }
        }
    }
`